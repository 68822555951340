'use client';

import { PropsWithChildren } from 'react';
import { useSessionContext } from '@dop-ui/react/features/authenticate';
import { FaviconTitleProvider } from '@dop-ui/react/app/providers/ui/favicon-title-provider';

export function AuthedFaviconTitleProvider({ children }: PropsWithChildren) {
  const { me } = useSessionContext();

  const displayInfo = me.displayConfig;

  return (
    <FaviconTitleProvider
      browserTitle={displayInfo.BROWSER_TITLE}
      faviconImageURL={displayInfo.FAVICON_IMAGE}
    >
      {children}
    </FaviconTitleProvider>
  );
}
